@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* In your CSS or SCSS file */
/* In your CSS or SCSS file */
/* In your CSS or SCSS file */


/* Add this CSS to your stylesheet */

/* Hide horizontal scrollbar */
::-webkit-scrollbar {
    display: none;
}

/* Hide vertical scrollbar */
::-webkit-scrollbar-thumb {
    display: none;
}

/* Optional: Ensure smooth scrolling */
::-webkit-scrollbar {
    scrollbar-width: none;
}

/* Optional: Hide scrollbar for Firefox */
body {
    scrollbar-width: none;
}